import { t } from "i18next";
import { useContext } from "react";
import { SkinContext } from "../../../pages/OrderBoard";

const Menu = ({ name, price, image, addChoiceMenu }) => {
  const nowSkin = useContext(SkinContext)
  switch (nowSkin) {
    case "v11": return (
    <div
      className="w-[21vw] h-[29vw] p-[1vw]"
      onClick={addChoiceMenu}
    >
      <div className="w-[19vw] h-[20vw] flex justify-center items-center">
        <img src={image} alt={name} className="object-cover bg-white w-full h-full rounded-2xl" />
      </div>
      <div className="py-[1.5vw] leading-8">
        <div className="text-[2.2vw] text-[#727272] font-bold overflow-hidden text-ellipsis whitespace-nowrap">
          {name}
        </div>
        <div className="text-[2vw] font-semibold tracking-tighter">
          {price.toLocaleString()}
        </div>
      </div>
    </div>
    );
    case "v12": return (
    <div
      className="w-[24vw] h-[32vw] p-[1vw]"
      onClick={addChoiceMenu}
    >
      <div className="w-[22vw] h-[22vw] flex justify-center items-center">
        <img src={image} alt={name} className="object-cover bg-white w-full h-full rounded-2xl" />
      </div>
      <div className="py-[1.5vw] leading-8">
        <div className="text-[2.2vw] text-[#727272] font-bold overflow-hidden text-ellipsis whitespace-nowrap">
          {name}
        </div>
        <div className="text-[2vw] font-semibold tracking-tighter">
          {price.toLocaleString()}
        </div>
      </div>
    </div>
    );
    case "v13": return (
    <div
      className="bg-white w-[20vw] h-[38vw] p-[1vw] border border-gray-300 rounded-lg shadow-xl"
      onClick={addChoiceMenu}
    >
      <div className="w-[18vw] h-[26vw] flex justify-center items-center">
        <img src={image} alt={name} className="object-cover w-full h-full" />
      </div>
      <div className="text-center py-[2.2vw]">
        <div className="text-[2.2vw] font-normal overflow-hidden text-ellipsis whitespace-nowrap">
          {name}
        </div>
        <div className="text-[2.5vw] m-[-0.5vw] font-bold tracking-tighter">
          {price.toLocaleString()}
        </div>
      </div>
    </div>
    );
    case "v14": return (
    <div
      className="bg-white w-[20vw] h-[30vw] p-[1vw] border border-gray-300 rounded-lg shadow-xl"
      onClick={addChoiceMenu}
    >
      <div className="w-[18vw] h-[18vw] flex justify-center items-center">
        <img src={image} alt={name} className="object-cover w-full h-full" />
      </div>
      <div className="text-center py-[2.2vw]">
        <div className="text-[2.2vw] font-normal overflow-hidden text-ellipsis whitespace-nowrap">
          {name}
        </div>
        <div className="text-[2.5vw] m-[-0.5vw] font-bold tracking-tighter">
          {price.toLocaleString()}
        </div>
      </div>
    </div>
    );
    default: return (
      <div
        className="bg-white p-[1vw] border border-gray-300 rounded-l"
        onClick={addChoiceMenu}
      >
        <div className="w-full h-[12vw] overflow-hidden flex justify-center items-center">
          <img src={image} alt={name} className="object-contain w-full h-full" />
        </div>
        <div className="px-[0.5vw]">
          <div className="text-[2.2vw] font-normal overflow-hidden text-ellipsis whitespace-nowrap">
            {name}
          </div>
          <div className="text-[2vw] font-bold tracking-tighter">
            {price.toLocaleString()}
            {t("원")}
          </div>
        </div>
        <div className="w-[5vw] h-[3vw] bg-[#000] flex items-center justify-center rounded-lg text-white text-[1.5vw] float-right">
          {t("담기")}
        </div>
      </div>
    );
  }
};

export default Menu;
