import { forwardRef, useContext } from "react";
import Center from "./Center";
import Footer from "./Footer";
import Header from "./Header";
import { SkinContext } from "../../pages/OrderBoard";

let themeStyles = ""

const Main = forwardRef(
  
  (
    {
      categoryList,
      langList,
      selectedCategory,
      scrollSelectCategory,
      changeSelectedCategory,
      addChoiceMenu,
      choiceMenus,
      openShoppingModal,
      nowLang
    },
    ref
  ) => {
    const nowSkin = useContext(SkinContext)
    switch (nowSkin) {
      case "v11": case "v12": themeStyles = ["w-full h-full overflow-auto",]; break;
      case "v13": case "v14": default: themeStyles = ["pl-[11.6vw] w-full h-full overflow-auto",]
    }
    return (
      <div className={themeStyles}>
        <Header
          categoryList={categoryList}
          selectedCategory={selectedCategory}
          scrollSelectCategory={scrollSelectCategory}
          changeSelectedCategory={changeSelectedCategory}
        />
        <Center
          categoryList={categoryList}
          changeSelectedCategory={changeSelectedCategory}
          selectedCategory={selectedCategory}
          addChoiceMenu={addChoiceMenu}
          ref={ref}
        />
        <Footer
          openShoppingModal={openShoppingModal}
          choiceMenus={choiceMenus}
          langList={langList}
          nowLang={nowLang}
        />
      </div>
    );
  }
);

export default Main;
