import { forwardRef, useContext } from "react";
import Menu from "./Menu";
import { SkinContext } from "../../../pages/OrderBoard";

const Menus = forwardRef(
  ({ categoryId, categoryTitle, menus, addChoiceMenu }, ref) => {
    const nowSkin = useContext(SkinContext)
    switch (nowSkin) {
      case "v14": return (
        <div
          className="flex flex-col my-2 mb-4 gap-[2vw]"
          id={categoryId}
          ref={ref}
        >
          <div className="w-full h-[5vw] text-[#909090] flex items-center text-[3vw] font-bold pl-[2vw] pt-[1vw]">
            {categoryTitle}
          </div>
          <div className="grid grid-cols-4 px-[2vw] auto-rows-[minmax(23vw,_auto)] justify-center items-center gap-[2vw]">
            {menus.map((menu) => (
              <Menu
                key={menu.id}
                addChoiceMenu={(e) => {
                  addChoiceMenu(e, categoryId, menu.id, true);
                }}
                name={menu.name}
                price={menu.price}
                image={menu.image}
              />
            ))}
          </div>
        </div>
      );
      default: return (
        <div
          className="flex flex-col gap-[2vw] py-[2vw]"
          id={categoryId}
          ref={ref}
        >
          <div className="w-full h-[5vw] bg-[#D9D9D9] flex items-center text-[2vw] pl-[2vw]">
            {categoryTitle}
          </div>
          <div className="grid grid-cols-3 px-[2vw] auto-rows-[minmax(23vw,_auto)] justify-center items-center gap-[2vw]">
            {menus.map((menu) => (
              <Menu
                key={menu.id}
                addChoiceMenu={(e) => {
                  addChoiceMenu(e, categoryId, menu.id, true);
                }}
                name={menu.name}
                price={menu.price}
                image={menu.image}
              />
            ))}
          </div>
        </div>
      );
    }
  }
);

export default Menus;
