import Main from "../components/OrderBoard/Main";
import Menu from "../components/OrderBoard/Side/Menu";
import ChatsIcon from "../assets/images/mint-messages-square.svg";
import ChatIcon from "../assets/images/mint-message-circle.svg";
import KoreanFlag from "../assets/images/KoreanFlag.png";
import MusicIcon from '../assets/images/MusicIcon.png'
import MenuOrderIcon from "../assets/images/menuOrder.svg";
import BannerIcon from '../assets/images/bannericon.png'
import Shopping from "../components/OrderBoard/Modal/Shopping";
import React, { useEffect, useRef, useState } from "react";
import Call from "../components/OrderBoard/Modal/Call";
import FunList from "../components/OrderBoard/Side/FunList";
import FunIcon from "../assets/images/FunIcon.svg";
import Lang from "../components/OrderBoard/Modal/Lang";
import { t } from "i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Payment from "../components/OrderBoard/Modal/Payment";
import MessageBox from "../components/OrderBoard/Modal/MessageBox";
import OrderedMenu from "../components/OrderedMenu";

export const SkinContext = React.createContext()

const OrderBoard = ({
  langList,
  setPurchasedMenus,
  getMenuByCategoryIdAndMenuId,
  changeType,
  categoryList,
  nowLang,
  changeLang,
}) => {
  const [nowShowModal, setNowShowModal] = useState(null);
  const [selectedSideMenu, setSelectedSideMenu] = useState(1);
  const [choiceMenus, setChoiceMenus] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isShowNoChoiceMessageBox, setIsShowNoChoiceMessageBox] =
  useState(false);
  const categoryRefs = useRef([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShowSelectedMenuInfo, setIsShowSelectedMenuInfo] = useState({
    show: false,
    x: null,
    y: null,
    image: null,
  });
  const navigate = useNavigate();
  
  const [nowSkin, setNowSkin] = useState("")
  
  let selectedMenuTimeOut = null;
  
  const addChoiceMenu = (e, categoryId, menuId, isClick = false) => {
    if (isClick) {
      clearTimeout(selectedMenuTimeOut);
      
      setIsShowSelectedMenuInfo({
        show: true,
        x:
        window.innerWidth -
        e.currentTarget.offsetWidth * 0.8 -
        e.currentTarget.offsetLeft,
        y:
        window.innerHeight -
        e.currentTarget.offsetHeight * 0.8 -
        e.currentTarget.offsetTop +
        categoryRefs.current["menu"].scrollTop,
        image: e.currentTarget.children[0].children[0].src,
      });
      
      selectedMenuTimeOut = setTimeout(() => {
        setIsShowSelectedMenuInfo({
          show: false,
          x: null,
          y: null,
          image: null,
        });
      }, 500);
    }
    
    const hasMenu = choiceMenus.findIndex(
      (menu) => menu.categoryId == categoryId && menu.menuId == menuId
    );
    
    if (hasMenu < 0) {
      setChoiceMenus([...choiceMenus, { categoryId, menuId, amount: 1 }]);
      
      return;
    }
    
    const _choiceMenus = [...choiceMenus];
    
    _choiceMenus[hasMenu].amount++;
    
    setChoiceMenus(_choiceMenus);
  };
  
  const removeChoiceMenu = (categoryId, menuId) => {
    const hasMenu = choiceMenus.findIndex(
      (menu) => menu.categoryId == categoryId && menu.menuId == menuId
    );
    
    if (hasMenu < 0) {
      return;
    }
    
    const _choiceMenus = [...choiceMenus];
    
    if (_choiceMenus[hasMenu].amount <= 1) return;
    
    _choiceMenus[hasMenu].amount--;
    
    setChoiceMenus(_choiceMenus);
  };
  
  const deleteChoiceMenu = (categoryId, menuId) => {
    const hasMenu = choiceMenus.findIndex(
      (menu) => menu.categoryId == categoryId && menu.menuId == menuId
    );
    
    if (hasMenu < 0) {
      return;
    }
    
    const _choiceMenus = [...choiceMenus];
    
    _choiceMenus.splice(hasMenu, 1);
    
    setChoiceMenus(_choiceMenus);
  };
  
  const purchase = (isPay) => {
    setPurchasedMenus({ menus: choiceMenus, isPay });
    navigate("/log");
  };
  
  const purchaseDutchPay = () => {
    navigate("/dutchpay");
  };
  
  const openCallModal = () => {
    setNowShowModal(1);
  };
  
  const openShoppingModal = () => {
    setNowShowModal(2);
  };

  const openNoChoiceMessageBox = () => {
    setIsShowNoChoiceMessageBox(true);
  };
  
  const closeNoChoiceMessageBox = () => {
    setIsShowNoChoiceMessageBox(false);
  };
  
  const openFunModal = () => {
    setNowShowModal(3);
  };
  
  const closeSideMenuModal = () => {
    setSelectedSideMenu(1);
    setNowShowModal(null);
  };
  
  const openLangModal = () => {
    setNowShowModal(4);
  };
  
  const changeSelectedCategory = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  
  const emptyShopping = () => {
    setChoiceMenus([]);
  };
  
  const openCallConfirmModal = () => {
    setNowShowModal(5);
  };

  const scrollSelectCategory = (categoryId) => {
    categoryRefs.current[categoryId].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    })
  };
  
  const closeModal = () => {
    setNowShowModal(null);
  };
  
  useEffect(() => {
    changeType(
      searchParams.get("type") ? parseInt(searchParams.get("type")) : 1
    );
    setNowSkin(
      searchParams.get('skin') != "" ? searchParams.get('skin') : 'v21'
    )
  }, []);
  
  
  
  useEffect(() => {
    changeLang(searchParams.get("lang"));
  }, [searchParams.get("lang")]);
  
  useEffect(() => {
    if (nowSkin === "v21") {
      if (selectedSideMenu == 2) {
        openFunModal();
        
        return;
      }
      
      if (selectedSideMenu == 3) {
        openLangModal();
        
        return;
      }
      
      setNowShowModal(null);
    }
    }, [selectedSideMenu]);
    
    const openPaymentModal = () => {
      if (choiceMenus.length == 0) {
        openNoChoiceMessageBox();
        
        return;
      }
    
    setIsOpenPaymentModal(true);
  };
  
  const closePaymentModal = () => {
    setIsOpenPaymentModal(false);
  };
  
  const modalList = [
    {
      id: 1,
      modal: (
        <Call
        closeModal={closeModal}
        openCallConfirmModal={openCallConfirmModal}
        />
      ),
    },
    {
      id: 2,
      modal: (
        <Shopping
          closeModal={closeModal}
          choiceMenus={choiceMenus}
          getMenuByCategoryIdAndMenuId={getMenuByCategoryIdAndMenuId}
          emptyShopping={emptyShopping}
          addChoiceMenu={addChoiceMenu}
          removeChoiceMenu={removeChoiceMenu}
          openPaymentModal={openPaymentModal}
          deleteChoiceMenu={deleteChoiceMenu}
        />
      ),
    },
    {
      id: 3,
      modal: <FunList closeModal={closeSideMenuModal} />,
    },
    {
      id: 4,
      modal: <Lang closeModal={closeSideMenuModal} langList={langList} />,
    },
    {
      id: 5,
      modal: (
        <MessageBox
        title={t("정상적으로 호출되었습니다.")}
        content=""
        closeModal={closeModal}
        />
      ),
    },
  ];
  
  switch (nowSkin) {
    case "v11": case "v12": {
      const sideMenuList = [
        { id: 1, title: "단체채팅", uri: "/chatting", icon: ChatsIcon, css: "bg-[#0FCBB0]" },
        { id: 2, title: "1:1채팅", uri: "/chatselection", icon: ChatIcon, css: "bg-[#46A6E0]" },
        { id: 3, title: "신청곡 넣기", uri: "/song", icon: MusicIcon, css: "bg-[#834CFF]" },
        { id: 4, title: "게임", uri: "/game", icon: FunIcon, css: "bg-[#FF9C01]"}
      ];

      return (
        <SkinContext.Provider value={nowSkin}>
          <div className="w-screen fixed">
            {isShowSelectedMenuInfo.show && (
              <OrderedMenu
              image={isShowSelectedMenuInfo.image}
              x={isShowSelectedMenuInfo.x}
              y={isShowSelectedMenuInfo.y}
              />
            )}
            {isShowNoChoiceMessageBox && (
              <MessageBox
              title={t("장바구니에 상품이 없습니다.")}
              content={t("상품을 추가해 주세요.")}
              closeModal={closeNoChoiceMessageBox}
              />
            )}
            {isOpenPaymentModal && (
              <Payment
              closePaymentModal={closePaymentModal}
              purchase={purchase}
              purchaseDutchPay={purchaseDutchPay}
              />
            )}
            {modalList.find((modal) => modal.id == nowShowModal)?.modal}
            {/* <MenuDetail /> */}
            <div className="flex top-0 w-full h-[5vw] p-[0.6vw] bg-[#000] z-0">
              <div className="bg-[#000]">
                <div className="w-[3.8vw] h-[3.8vw] rounded-[15%] bg-white flex items-center justify-center">
                  <p className="text-[1.4vw] font-semibold leading-none">
                    하이
                    <br />
                    오더
                  </p>
                </div>
              </div>
              <div className="w-full flex justify-end">
                <div className="flex h-[3.8vw] py-[0.3vw] gap-[0.8vw] mr-[10vw]">
                  {sideMenuList.map((menu) => {
                    let icon = menu.icon;
                    return (
                      <Menu
                        key={menu.id}
                        id={menu.id}
                        title={menu.title}
                        uri={menu.uri}
                        icon={icon}
                        css={menu.css}
                        selected={selectedSideMenu === menu.id}
                        onClick={() => setSelectedSideMenu(menu.id)}
                      />
                    );
                  })}
                </div>
                <Link to={"/admin"} className="text-white text-[3vw] font-semibold leading-[1] mr-[15vw]">
                  12
                </Link>
              </div>
            </div>
            <Main
              categoryList={categoryList}
              selectedCategory={selectedCategory}
              scrollSelectCategory={scrollSelectCategory}
              changeSelectedCategory={changeSelectedCategory}
              openShoppingModal={openShoppingModal}
              addChoiceMenu={addChoiceMenu}
              choiceMenus={choiceMenus}
              ref={categoryRefs}
              langList={langList}
              nowLang={nowLang}
              />
          </div>
        </SkinContext.Provider>
      );
    }
    case "v13" : case "v14": {
      const isV13 = nowSkin == "v13"
      const sideMenuList = [
        { id: 1, title: "메뉴주문", uri:`/display?skin=${nowSkin}`, icon: BannerIcon },
        { id: 2, title: "단체채팅", uri: "/chatting", icon: ChatsIcon },
        { id: 3, title: "1:1채팅", uri: "/chatselection", icon: ChatIcon },
        { id: 4, title: "신청곡", uri: "/song", icon: MusicIcon },
        { id: 5, title: "네이버리뷰이벤트", icon: BannerIcon, uri: "/bannernaver" },
        { id: 6, title: "와이파이", icon: BannerIcon, uri: "/bannerwifi" },
        { id: 7, title: "인스타팔로우이벤트", icon: BannerIcon, uri: "/bannerinsta" },
      ];

      return (
        <SkinContext.Provider value={nowSkin}>
          <div className="flex w-full h-screen">
            {isShowSelectedMenuInfo.show && (
              <OrderedMenu
              image={isShowSelectedMenuInfo.image}
              x={isShowSelectedMenuInfo.x}
              y={isShowSelectedMenuInfo.y}
              />
            )}
            {isShowNoChoiceMessageBox && (
              <MessageBox
              title={t("장바구니에 상품이 없습니다.")}
              content={t("상품을 추가해 주세요.")}
              closeModal={closeNoChoiceMessageBox}
              />
            )}
            {isOpenPaymentModal && (
              <Payment
              closePaymentModal={closePaymentModal}
              purchase={purchase}
              purchaseDutchPay={purchaseDutchPay}
              />
            )}
            {modalList.find((modal) => modal.id == nowShowModal)?.modal}
            {/* <MenuDetail /> */}
            <div className="fixed flex flex-col top-0 w-[10w] h-full bg-[#000] z-0">
              <div className="w-full h-[13vw] bg-[#000] flex flex-col gap-[1vw] items-center justify-center">
                <div className="w-[6.7vw] h-[6.7vw] max-w-40 max-h-40 rounded-[15%] bg-white flex items-center justify-center">
                  <p className="text-[2.3vw] font-semibold leading-none">
                    하이
                    <br />
                    오더
                  </p>
                </div>
                <Link to={"/admin"} className="text-white text-[2.3vw] font-semibold leading-4">
                  12
                </Link>
              </div>
              <div className="flex-1 w-full flex flex-col justify-between">
                <div className={`flex flex-col overflow-auto ${isV13 ? 'h-[42.7vw]' : 'h-[31.5vw]'}`}>
                  {sideMenuList.map((menu) => {
                    let icon = menu.icon;
                    
                    return (
                      <Menu
                        key={menu.id}
                        id={menu.id}
                        title={menu.title}
                        uri={menu.uri}
                        icon={icon}
                        selected={selectedSideMenu === menu.id}
                        onClick={() => setSelectedSideMenu(menu.id)}
                      />
                    );
                  })}
                </div>
                {!isV13 && <div className="flex flex-1 w-full h-[12.2vw] border-t-[0.1vw] border-t-[#282828] items-center justify-center relative">
                  <div
                    className="bg-[#FF9C01] text-white rounded-[50%] w-[9vw] h-[9vw] flex justify-center items-center absolute cursor-pointer"
                    onClick={openCallModal}
                    >
                    <div className="w-full text-center text-[1.8vw] break-words leading-[2.4vw]">
                      {t("직원호출")
                        .split("\n")
                        .map((line, i) => (
                          <div key={i}>{line}</div>
                        ))}
                    </div>
                  </div>
                </div> }
              </div>
            </div>
            <Main
              categoryList={categoryList}
              selectedCategory={selectedCategory}
              scrollSelectCategory={scrollSelectCategory}
              changeSelectedCategory={changeSelectedCategory}
              openShoppingModal={openShoppingModal}
              addChoiceMenu={addChoiceMenu}
              choiceMenus={choiceMenus}
              ref={categoryRefs}
              langList={langList}
              nowLang={nowLang}
              />
          </div>
        </SkinContext.Provider>
      );
    }
    default: {
      const sideMenuList = [
        { id: 1, title: "메뉴주문", icon: MenuOrderIcon },
        { id: 2, title: "FUN", icon: FunIcon },
        { id: 3, title: "LANG", icon: KoreanFlag },
        { id: 4, title: "네이버 리뷰이벤트", icon: "naver" },
        { id: 5, title: "인스타 팔로우 이벤트", icon: "insta" },
        { id: 6, title: "와이파이", icon: "wifi" },
      ];

      return (
        <SkinContext.Provider value={nowSkin}>
          <div className="flex w-full h-screen">
            {isShowSelectedMenuInfo.show && (
              <OrderedMenu
              image={isShowSelectedMenuInfo.image}
              x={isShowSelectedMenuInfo.x}
              y={isShowSelectedMenuInfo.y}
              />
            )}
            {isShowNoChoiceMessageBox && (
              <MessageBox
              title={t("장바구니에 상품이 없습니다.")}
              content={t("상품을 추가해 주세요.")}
              closeModal={closeNoChoiceMessageBox}
              />
            )}
            {isOpenPaymentModal && (
              <Payment
              closePaymentModal={closePaymentModal}
              purchase={purchase}
              purchaseDutchPay={purchaseDutchPay}
              />
            )}
            {modalList.find((modal) => modal.id == nowShowModal)?.modal}
            {/* <MenuDetail /> */}
            <div className="fixed flex flex-col top-0 w-[12.2w] h-full bg-[#222222] z-0">
              <div className="w-[100%] h-[16.5vw] bg-[#000] flex flex-col gap-[1.2vw] items-center justify-center">
                <div className="w-[9.5vw] h-[9.5vw] max-w-40 max-h-40 rounded-[30%] bg-white flex items-center justify-center">
                  <p className="text-[3vw] font-bold leading-none">
                    하이
                    <br />
                    오더
                  </p>
                </div>
                <Link to={"/admin"} className="text-white text-[2.5vw] font-semibold">
                  12
                </Link>
              </div>
              <div className="flex-1 w-[12.2vw] flex flex-col justify-between">
                <div className="h-[26.4vw] flex flex-col overflow-auto">
                  {sideMenuList.map((menu) => {
                    let icon = menu.icon;
                    
                    if (menu.id == 3 && nowLang) {
                      const findLang = langList.find((lang) => lang.name == nowLang);
                      
                      icon = findLang.icon;
                    }
                    
                    return (
                      <Menu
                      key={menu.id}
                      id={menu.id}
                      title={menu.title}
                      icon={icon}
                      selected={selectedSideMenu === menu.id}
                      onClick={() => {
                        menu.id < 4 ? setSelectedSideMenu(menu.id) : setIsOpenPaymentModal()
                      }}
                      />
                    );
                  })}
                </div>
                <div className="flex flex-1 w-[12.2vw] h-[12.1vw] border-t-[0.1vw] border-t-[#222222] items-center justify-center relative">
                  <div
                    className="bg-[#46B0AE] text-white rounded-[50%] w-[10vw] h-[10vw] flex justify-center items-center absolute bottom-2 cursor-pointer"
                    onClick={openCallModal}
                    >
                    <div className="w-full text-center text-[1.8vw] break-words">
                      {t("직원호출")
                        .split("\n")
                        .map((line, i) => (
                          <div key={i}>{line}</div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Main
              categoryList={categoryList}
              selectedCategory={selectedCategory}
              scrollSelectCategory={scrollSelectCategory}
              changeSelectedCategory={changeSelectedCategory}
              openShoppingModal={openShoppingModal}
              addChoiceMenu={addChoiceMenu}
              choiceMenus={choiceMenus}
              ref={categoryRefs}
              langList={langList}
              nowLang={nowLang}
              />
          </div>
        </SkinContext.Provider>
      );
    }
  }
};

export default OrderBoard;
