import { useContext } from "react";
import { SkinContext } from "../../pages/OrderBoard";
import Category from "./Header/Category";
import { Link } from "react-router-dom";

const Header = ({ categoryList, selectedCategory, scrollSelectCategory, changeSelectedCategory }) => {
  
  const nowSkin = useContext(SkinContext)
  
  switch (nowSkin) {
    case "v11": {
      const bannerMenu = [
        { id: 1, title: "네이버리뷰이벤트", uri: "/bannernaver" },
        // { id: 2, title: "와이파이", uri: "/bannerwifi" },
        { id: 3, title: "인스타팔로우이벤트", uri: "/bannerinsta" },
        { id: 4, title: "상품 할인권", uri: "/gifticon" }
      ]
      return (
        <div className="fixed w-[67vw] flex top-[5vw] h-[7vw] border-b-1 border-[#F1F1F1] bg-white">
          <div className="flex-1 flex flex-nowrap overflow-x-scroll whitespace-nowrap">
            {categoryList.map((category) => (
              <Category
                nowSkin={nowSkin}
                key={category.id}
                title={category.title}
                selected={category.id == selectedCategory}
                onClick={() => changeSelectedCategory(category.id)}
              />
            ))}
            {bannerMenu.map((menu) => (
              <Link
                to={menu.uri}
                className="min-w-[10vw] max-w-[10vw] border-r-[0.1vw]"
              >
                <div className="flex text-center items-center justify-center w-full h-full text-[1.8vw] font-medium cursor-pointer break-words whitespace-normal leading-[2.4vw]">
                  {menu.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      );
    }
    case "v12": {
      const bannerMenu = [
        { id: 1, title: "네이버리뷰이벤트", icon: "naver", uri: "/bannernaver" },
        { id: 2, title: "와이파이", icon: "wifi", uri: "/bannerwifi" },
        { id: 3, title: "인스타팔로우이벤트", icon: "insta", uri: "/bannerinsta" },
      ]
      return (
        <div className="fixed w-full flex top-[5vw] h-[7vw] border-b-1 border-[#F1F1F1] bg-white">
          <div className="flex-1 flex flex-nowrap overflow-x-scroll whitespace-nowrap">
            {categoryList.map((category) => (
              <Category
                nowSkin={nowSkin}
                key={category.id}
                title={category.title}
                selected={category.id == selectedCategory}
                onClick={() => changeSelectedCategory(category.id)}
              />
            ))}
            {bannerMenu.map((menu) => (
              <Link
                to={`/banner${menu.icon}`}
                className="min-w-[11vw] max-w-[11vw] border-r-[0.1vw]"
              >
                <div className="flex text-center items-center justify-center w-full h-full text-[1.8vw] font-medium cursor-pointer break-words whitespace-normal leading-[2.4vw]">
                  {menu.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      );
    }
    case "v13": case "v14": return (
      <div className="fixed w-[87.6vw] top-0 left-[1`.6vw] flex h-[6vw] border-b-1 border-[#F1F1F1] bg-white shadow-xl">
        <div className="w-[7%] h-full bg-white items-center justify-center flex">
        </div>
        <div className="flex-1 flex flex-nowrap overflow-x-scroll whitespace-nowrap">
          {categoryList.map((category) => (
            nowSkin == 'v13' ? <Category
              nowSkin={nowSkin}
              key={category.id}
              title={category.title}
              selected={category.id == selectedCategory}
              onClick={() => changeSelectedCategory(category.id)}
            />
            : <Category
              nowSkin={nowSkin}
              key={category.id}
              title={category.title}
              selected={category.id == selectedCategory}
              onClick={() => scrollSelectCategory(category.id)}
            />
          ))}
        </div>
        <div className="w-[2.8vw] h-[2.8vw] m-[1.6vw] bg-gray-300 rounded-full items-center justify-center flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-[1.5vw] h-[1.5vw] fill-[#F1F1F1]"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M8.127,24l9.507-9.52a3.507,3.507,0,0,0,0-4.948L8.116,0,6,2.121l9.518,9.531a.5.5,0,0,1,0,.707L6.01,21.879Z" />
          </svg>
        </div>
      </div>
    );
    default: return (
      <div className="fixed w-[87.6vw] top-0 left-[12.2vw] flex h-[8vw] border-b-1 border-[#F1F1F1] bg-white">
        <div className="w-[7%] h-full bg-white items-center justify-center flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-[2.5vw] h-[2.5vw] fill-[#F1F1F1]"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M16.041,24,6.534,14.48a3.507,3.507,0,0,1,0-4.948L16.052,0,18.17,2.121,8.652,11.652a.5.5,0,0,0,0,.707l9.506,9.52Z" />
          </svg>
        </div>
        <div className="flex-1 flex flex-nowrap overflow-x-scroll whitespace-nowrap">
          {categoryList.map((category) => (
            <Category
              nowSkin={nowSkin}
              key={category.id}
              title={category.title}
              selected={category.id == selectedCategory}
              onClick={() => scrollSelectCategory(category.id)}
            />
          ))}
        </div>
        <div className="w-[7%] h-full bg-white items-center justify-center flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-[2.5vw] h-[2.5vw] fill-[#F1F1F1]"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
          >
            <path d="M8.127,24l9.507-9.52a3.507,3.507,0,0,0,0-4.948L8.116,0,6,2.121l9.518,9.531a.5.5,0,0,1,0,.707L6.01,21.879Z" />
          </svg>
        </div>
      </div>
    );
  }
};

export default Header;
