const Category = ({ nowSkin, title, selected, onClick }) => {
  switch (nowSkin) {
    case "v11": return (
      <div
        className={`min-w-[10vw] max-w-[10vw] flex text-[1.8vw] border-r-[0.1vw] font-medium items-center justify-center px-[3vw] cursor-pointer ${
          selected
            ? "bg-[#EE0A45] text-white"
            : "text-black py-[1vw]"
        }`}
        onClick={onClick}
      >
        {title}
      </div>
    );
    case "v11": case 'v12': return (
      <div
        className={`min-w-[11vw] max-w-[11vw] flex text-[1.8vw] border-r-[0.1vw] font-medium items-center justify-center px-[3vw] cursor-pointer ${
          selected
            ? "bg-[#EE0A45] text-white"
            : "text-black py-[1vw]"
        }`}
        onClick={onClick}
      >
        {title}
      </div>
    );
    case "v13": case "v14": return (
      <div
        className={`flex text-[2.2vw] mx-3 font-medium items-center justify-center px-[1.1vw] cursor-pointer ${
          selected
            ? "text-[#751fff] border-b-[#751fff] border-b-[0.5vw] pt-[0.4vw] font-semibold"
            : "text-black py-[1vw] font-normal"
        }`}
        onClick={onClick}
      >
        {title}
      </div>
    );
    default: return (
      <div
        className={`flex text-[2.5vw] font-medium items-center justify-center px-[3vw] cursor-pointer ${
          selected
            ? "text-[#5FCEC7] border-b-[#5FCEC7] border-b-[0.5vw] pt-[0.4vw]"
            : "text-black py-[1vw]"
        }`}
        onClick={onClick}
      >
        {title}
      </div>
    );
  }
};

export default Category;
