import { useNavigate } from 'react-router-dom';
import bannernaver from '../assets/images/bannernaver.png'
import bannerinsta from '../assets/images/bannerinsta.png'
import bannerwifi from '../assets/images/bannerwifi.png'
import CancelIcon from './CancelIcon';

const Banner = ({ type }) => {
    const navigate = useNavigate();
    
    const moveBack = () => {
        navigate(-1);
      };

    const banner = (value) => {
        switch (value) {
            case "wifi": return bannerwifi
            case "naver": return bannernaver
            default: return bannerinsta
        }
    }
    return (
        <div className="relative w-full h-full flex justify-center">
            <div
                className="flex absolute w-15 h-15 rounded-full right-0 p-1 m-2 bg-slate-700"
                onClick={moveBack}
                >
                <CancelIcon
                    width="3.2vw"
                    height="3.2vw"
                    stokeWidth="2"
                    color="white"
                />
            </div>
            <img
                className='h-screen w-screen'
                src={banner(type)}
                alt={type}
                />
        </div>
    )
}

export default Banner;