import { forwardRef, useContext, useRef } from "react";
import Menus from "./Center/Menus";
import { SkinContext } from "../../pages/OrderBoard";
import Menu from "./Center/Menu";

const Center = forwardRef(
  ({ categoryList, changeSelectedCategory, selectedCategory, addChoiceMenu }, ref) => {
    const onMenuScroll = (e) => {
      if (
        ref.current[ref.current.length - 1].getBoundingClientRect().top -
          ref.current[ref.current.length - 1].offsetHeight -
          window.innerWidth * 0.09 <
        0
      ) {
        changeSelectedCategory(
          ref.current[ref.current.length - 1].getAttribute("id")
        );

        return;
      }

      ref.current.forEach((rr) => {
        if (rr.getBoundingClientRect().top - window.innerWidth * 0.09 <= 0) {
          changeSelectedCategory(rr.getAttribute("id"));
        }
      });
    };

    const nowSkin = useContext(SkinContext)
    switch (nowSkin) {
      case "v11": {
        const categoryId = selectedCategory - 1
        const menus = categoryList[categoryId].menus
        return (
          <div
            className="w-full h-[calc(100vh-11vw)] mt-[6vw] flex flex-col pr-[33vw] bg-[#ececec] overflow-auto"
            ref={(e) => {
              ref.current["menu"] = e;
            }}
          >
            <div className="flex mt-[2vw] ml-[2vw] mb-[-2vw]">
              <div className="font-semibold text-[#7c7c7c] text-[2vw]">{categoryList[categoryId].title}</div>
              <div className="font-semibold text-[#aaaaaa] text-[1.2vw] leading-[4vw] mx-[0.3vw]">{menus.length}개의 상품</div>
            </div>
            <div
              className="flex flex-col mb-4 gap-[1vw]"
              id={categoryId}
              ref={ref}
            >
              <div className="w-full h-[1vw]" />
              <div className="grid grid-cols-3 px-[1vw] auto-rows-[minmax(23vw,_auto)] items-center gap-[1vw]">
                {menus.map((menu) => (
                  <Menu
                    key={menu.id}
                    addChoiceMenu={(e) => {
                      addChoiceMenu(e, categoryId + 1, menu.id, true);
                    }}
                    name={menu.name}
                    price={menu.price}
                    image={menu.image}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      }
      case "v12": {
        const categoryId = selectedCategory - 1
        const menus = categoryList[categoryId].menus
        return (
          <div
            className="w-full h-[calc(100vh-17.5vw)] my-[6vw] flex flex-col bg-[#ececec] overflow-auto"
            ref={(e) => {
              ref.current["menu"] = e;
            }}
          >
            <div className="flex mt-[2vw] ml-[2vw] mb-[-2vw]">
              <div className="font-semibold text-[#7c7c7c] text-[2vw]">{categoryList[categoryId].title}</div>
              <div className="font-semibold text-[#aaaaaa] text-[1.2vw] leading-[4vw] mx-[0.3vw]">{menus.length}개의 상품</div>
            </div>
            <div
              className="flex flex-col mb-4 gap-[1vw]"
              id={categoryId}
              ref={ref}
            >
              <div className="w-full h-[1vw]" />
              <div className="grid grid-cols-4 px-[1vw] auto-rows-[minmax(23vw,_auto)] items-center gap-[1vw]">
                {menus.map((menu) => (
                  <Menu
                    key={menu.id}
                    addChoiceMenu={(e) => {
                      addChoiceMenu(e, categoryId + 1, menu.id, true);
                    }}
                    name={menu.name}
                    price={menu.price}
                    image={menu.image}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      }
      case "v13": {
        const categoryId = selectedCategory - 1
        const menus = categoryList[categoryId].menus
        return (
          <div
            className="w-full h-[calc(100vh-12.5vw)] my-[6vw] flex bg-[#ececec] overflow-y-hidden"
            ref={(e) => {
              ref.current["menu"] = e;
            }}
          >
            <div
              className="flex flex-col my-2 mb-4 gap-[2vw]"
              id={categoryId}
              ref={ref}
            >
              <div className="w-full h-[1vw]" />
              <div className="flex px-[2vw] auto-rows-[minmax(23vw,_auto)] items-center gap-[2vw]">
                {menus.map((menu) => (
                  <Menu
                    key={menu.id}
                    addChoiceMenu={(e) => {
                      addChoiceMenu(e, categoryId + 1, menu.id, true);
                    }}
                    name={menu.name}
                    price={menu.price}
                    image={menu.image}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      }
      case "v14": return (
        <div
          className="w-full h-[calc(100vh-12.5vw)] my-[6vw] flex flex-col bg-[#ececec] overflow-auto"
          onScroll={onMenuScroll}
          ref={(e) => {
            ref.current["menu"] = e;
          }}
        >
          {categoryList.map((category) => (
            <Menus
              key={category.id}
              categoryId={category.id}
              categoryTitle={category.title}
              menus={category.menus}
              addChoiceMenu={addChoiceMenu}
              ref={(e) => {
                ref.current[category.id] = e;
              }}
            />
          ))}
        </div>
      );
      default: return (
        <div
          className="w-full h-[calc(100vh-14vw)] my-[6vw] flex flex-col gap-[2vw] bg-[#ececec] overflow-auto"
          onScroll={onMenuScroll}
          ref={(e) => {
            ref.current["menu"] = e;
          }}
        >
          {categoryList.map((category) => (
            <Menus
              key={category.id}
              categoryId={category.id}
              categoryTitle={category.title}
              menus={category.menus}
              addChoiceMenu={addChoiceMenu}
              ref={(e) => {
                ref.current[category.id] = e;
              }}
            />
          ))}
        </div>
      );
    }
  }
);

export default Center;
