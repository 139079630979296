import { useSearchParams } from "react-router-dom";
import LangElement from "./Lang/LangElement";
import i18n from "../../../lang/i18n";
import { useContext } from "react";
import { SkinContext } from "../../../pages/OrderBoard";

const Lang = ({ langList, closeModal }) => {
  const [searchParams, setSeratchParams] = useSearchParams();
  const nowLang = searchParams.get("lang") ?? "KOR";

  const changeLang = (lang) => {
    const tmpSearchParams = new URLSearchParams(searchParams);
    tmpSearchParams.set("lang", lang);
    setSeratchParams(tmpSearchParams);
    i18n.changeLanguage(lang);
    closeModal();
  };

  const nowSkin = useContext(SkinContext)
  switch (nowSkin) {
    case "v11": return (
      <div className="w-full h-full fixed top-0 left-0 flex items-center z-10" onClick={closeModal}>
        <div className="w-[33vw] h-[calc(100vh-12vw)] bottom-0 right-0 fixed flex z-10">
            <div className="top-0 left-0 w-full h-[70%] grid grid-cols-2 relative z-[2]">
              {langList.map((langE) => (
                <LangElement
                key={langE.id}
                id={langE.icon}
                name={langE.name}
                icon={langE.icon}
                onClick={() => changeLang(langE.name)}
                isSelected={langE.name == nowLang}
                nowSkin={nowSkin}
                />
              ))}
            </div>
            <div
              className="w-full h-full bg-black opacity-60 absolute z-[1]"
              onClick={closeModal}
              />
          </div>
      </div>
    );
    default: return (
      <div className="w-full h-full fixed flex justify-center items-center z-10">
        <div className="grid grid-cols-4 auto-rows-[minmax(5vw,_auto)] relative z-[2] gap-[0.8vw]">
          {langList.map((langE) => (
            <LangElement
              key={langE.id}
              id={langE.icon}
              name={langE.name}
              icon={langE.icon}
              onClick={() => changeLang(langE.name)}
              isSelected={langE.name == nowLang}
              nowSkin={nowSkin}
            />
          ))}
        </div>
        <div
          className="w-full h-full bg-black opacity-80 absolute top-0 left-0 z-[1]"
          onClick={closeModal}
        ></div>
      </div>
    );
  }
};

export default Lang;
