import { Link } from "react-router-dom";
import gifts_image from "../../assets/images/gifts_image.png";
import Check from "../../assets/images/NotCircleCheckIcon.svg";
import Clerk from '../../assets/images/callclerk.png'
import { t } from "i18next";
import { useContext, useState } from "react";
import { SkinContext } from "../../pages/OrderBoard";
import Lang2 from "./Modal/Lang2";
import Lang from "./Modal/Lang";

const Footer = ({ openShoppingModal, choiceMenus, langList, nowLang }) => {
  const [nowShowModal, setNowShowModal] = useState(false);
  const nowSkin = useContext(SkinContext)

  const isV13 = nowSkin == 'v13'

  const openLangModal = () => {
    setNowShowModal(true);
  };
  const closeLangModal = () => {
    setNowShowModal(false);
  };
  
  const openLang1Modal = () => {
    setNowShowModal(true);
  };
  const closeLang1Modal = () => {
    setNowShowModal(false);
  };
  
  const lModal = {
    modal: <Lang2 closeModal={closeLangModal} langList={langList} />
  }

  const l1Modal = {
    modal: <Lang closeModal={closeLang1Modal} langList={langList} />
  }

  switch (nowSkin) {
    case "v11": {
      openShoppingModal()
      if (!nowLang) nowLang = "KOR"
      let findLang = langList.find((lang) => lang.name == nowLang)
      return (
        <div className="top-[5vw] right-0 w-[33vw] flex h-[7vw] absolute bg-[#2e2e2e] items-center justify-between">
          <div className="flex h-full items-center gap-[0.5vw]">

            <div className="w-[11vw] h-[100%] border-l-[0.1vw] border-r-[#343434] border-l-[#343434] bg-[#834CFF] font-normal">
              <Link to="/log" className="h-full flex flex-col justify-center items-center gap-[0.5vw]">
                <img
                  src={Clerk}
                  alt={t('직원 호출')}
                  className="w-[1.7vw] h[1.7vw] invert-[100%]"
                />
                <div className="text-white font-bold text-[1.6vw] leading-[2vw]">
                  {t("직원 호출")}
                </div>
              </Link>
            </div>

            <div className="w-[11vw] h-[100%] border-l-[0.1vw] border-r-[#343434] border-l-[#343434] font-normal">
              <Link to="/log" className="h-full flex flex-col justify-center items-center gap-[0.6vw]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 34 34"
                  fill="none"
                  className="w-[1.4vw] h-[1.4vw]"
                  >
                  <rect y="6.10352e-05" width="34" height="6" fill="#b0b0b0" />
                  <rect y="14.0001" width="34" height="6" fill="#b0b0b0" />
                  <rect y="28.0001" width="34" height="6" fill="#b0b0b0" />
                </svg>
                <div className="text-[#b0b0b0] text-[1.6vw] font-bold leading-[2vw]">
                  {t("주문내역")}
                </div>
              </Link>
            </div>
            
            {nowShowModal ? l1Modal.modal : false}
            <div className="cursor-pointer w-[11vw] h-[100%] border-l-[0.1vw] border-r-[#343434] border-l-[#343434] flex justify-center gap-[1vw] items-center"
              onClick={() => nowShowModal ? closeLang1Modal() : openLang1Modal()}>
              <img src={findLang.icon} alt="Lang" className="w-[4vw] h-[4vw] rounded-full object-cover" />
              <svg 
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34 34"
                fill="#b0b0b0"
                className="w-[1vw] h-[1vw]">
                <polygon points="0,0 25,50 50,0" transform="scale(0.7)" />
              </svg>
            </div>
          </div>
        </div>
      );
    }
    case "v12": return (
      <div className="fixed w-full bottom-0 flex h-[6.5vw] border-t-2 border-t-[#F1F1F1] bg-white items-center justify-between px-[0.4vw]">
        <div className="flex h-full items-center gap-[0.5vw]">
          {nowShowModal ? lModal.modal : false}
          <div className={`cursor-pointer w-[11.4vw] h-[75%] border-[0.1vw] flex justify-center gap-[0.3vw] items-center bg-white rounded-full ${nowShowModal ? "border-[#751fff]" : "border-[#DBDBDB]"}`}
            onClick={() => openLangModal()}>
            <div className={`text-[2.3vw] leading-[2vw] ${nowShowModal ? "text-[#751fff]" : "text-[#b0b0b0]"}`}>
              {t("LANG")}
            </div>
            <svg 
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 34 34"
              fill={nowShowModal ? "#751fff" : "#b0b0b0"}
              className="w-[1vw] h-[1vw]">
              <polygon points={nowShowModal ? "0,0 25,50 50,0" : "25,0 0,50 50,50"} transform="scale(0.7)" />
            </svg>
          </div>

          <div className="w-[12vw] h-[75%] border-[0.1vw] border-[#DBDBDB] bg-white rounded-full font-normal">
            <Link to="/log" className="h-full flex justify-center items-center gap-[0.6vw]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34 34"
                fill="none"
                className="w-[1.4vw] h-[1.4vw]"
                >
                <rect y="6.10352e-05" width="34" height="6" fill="#b0b0b0" />
                <rect y="14.0001" width="34" height="6" fill="#b0b0b0" />
                <rect y="28.0001" width="34" height="6" fill="#b0b0b0" />
              </svg>
              <div className="text-[#b0b0b0] text-[2vw] leading-[2vw]">
                {t("주문내역")}
              </div>
            </Link>
          </div>

          <div className="w-[12vw] h-[75%] border-[0.1vw] border-[#C86B16] bg-[#FB9B00] rounded-full font-normal">
            <Link to="/log" className="h-full flex justify-center items-center gap-[0.5vw]">
              <img
                src={Clerk}
                alt={t('직원 호출')}
                className="w-[1.7vw] h[1.7vw] invert-[100%]"
              />
              <div className="text-white text-[2vw] leading-[2vw]">
                {t("직원 호출")}
              </div>
            </Link>
          </div>

          <div className="w-[14vw] h-[75%] border-[0.1vw] border-[#DBDBDB] bg-white rounded-full font-normal">
            <Link
              to="/gifticon"
              className="w-full h-full flex items-center justify-center gap-[0.6vw]"
              >
              <img
                src={gifts_image}
                alt={t("상품 할인권")}
                className="w-[1.7vw] h-[1.7vw]"
                />
              <div className="text-[#b0b0b0] text-[2vw] leading-[2vw]">
                {t("상품 할인권")}
              </div>
            </Link>
          </div>
        </div>

        <div className="flex h-full items-center gap-2">
          <div
            className="w-[18vw] h-[75%] bg-[#EE0A45] rounded-full flex items-center justify-center gap-[1vw] hover: cursor-pointer"
            onClick={openShoppingModal}
          >
            <img src={Check} alt="장바구니확인" className="w-[2vw]" />
            <div className="text-white text-[2.3vw] font-semibold">
              {t("장바구니")}
            </div>
            <div className="rounded-full flex items-center justify-center text-[#DDFF00] text-[2.3vw] font-semibold">
              {choiceMenus.reduce(
                (total, choiceMenu) => total + choiceMenu.amount,
                0
              )}
            </div>
          </div>
        </div>
      </div>
    );
    case "v13": case "v14": return (
        <div className="fixed w-[88vw] bottom-0 left-[11.7vw] flex h-[6.5vw] border-t-2 border-t-[#F1F1F1] bg-white items-center justify-between px-[0.4vw]">
          <div className="flex h-full items-center gap-[0.5vw]">
            {nowShowModal ? lModal.modal : false}
            <div className={`cursor-pointer w-[11.4vw] h-[75%] border-[0.1vw] flex justify-center gap-[0.3vw] items-center bg-white rounded-full ${nowShowModal ? "border-[#751fff]" : "border-[#DBDBDB]"}`}
              onClick={() => openLangModal()}>
              <div className={`text-[2.3vw] leading-[2vw] ${nowShowModal ? "text-[#751fff]" : "text-[#b0b0b0]"}`}>
                {t("LANG")}
              </div>
              <svg 
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34 34"
                fill={nowShowModal ? "#751fff" : "#b0b0b0"}
                className="w-[1vw] h-[1vw]">
                <polygon points={nowShowModal ? "0,0 25,50 50,0" : "25,0 0,50 50,50"} transform="scale(0.7)" />
              </svg>
            </div>

            <div className="w-[12vw] h-[75%] border-[0.1vw] border-[#DBDBDB] bg-white rounded-full font-normal">
              <Link to="/log" className="h-full flex justify-center items-center gap-[0.6vw]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 34 34"
                  fill="none"
                  className="w-[1.4vw] h-[1.4vw]"
                  >
                  <rect y="6.10352e-05" width="34" height="6" fill="#b0b0b0" />
                  <rect y="14.0001" width="34" height="6" fill="#b0b0b0" />
                  <rect y="28.0001" width="34" height="6" fill="#b0b0b0" />
                </svg>
                <div className="text-[#b0b0b0] text-[2vw] leading-[2vw]">
                  {t("주문내역")}
                </div>
              </Link>
            </div>

            {isV13 && (
            <div className="w-[12vw] h-[75%] border-[0.1vw] border-[#C86B16] bg-[#FB9B00] rounded-full font-normal">
              <Link to="/log" className="h-full flex justify-center items-center gap-[0.5vw]">
                <img
                  src={Clerk}
                  alt={t('직원 호출')}
                  className="w-[1.7vw] h[1.7vw] invert-[100%]"
                />
                <div className="text-white text-[2vw] leading-[2vw]">
                  {t("직원 호출")}
                </div>
              </Link>
            </div> )}

            <div className="w-[14vw] h-[75%] border-[0.1vw] border-[#DBDBDB] bg-white rounded-full font-normal">
              <Link
                to="/gifticon"
                className="w-full h-full flex items-center justify-center gap-[0.6vw]"
                >
                <img
                  src={gifts_image}
                  alt={t("상품 할인권")}
                  className="w-[1.7vw] h-[1.7vw]"
                  />
                <div className="text-[#b0b0b0] text-[2vw] leading-[2vw]">
                  {t("상품 할인권")}
                </div>
              </Link>
            </div>
          </div>

          <div className="flex h-full items-center gap-2">
            <div
              className="w-[18vw] h-[75%] bg-[#751fff] rounded-full flex items-center justify-center gap-[1vw] hover: cursor-pointer"
              onClick={openShoppingModal}
            >
              <img src={Check} alt="장바구니확인" className="w-[2vw]" />
              <div className="text-white text-[2.3vw] font-semibold">
                {t("장바구니")}
              </div>
              <div className="rounded-full flex items-center justify-center text-[#27CACA] text-[2.3vw] font-semibold">
                {choiceMenus.reduce(
                  (total, choiceMenu) => total + choiceMenu.amount,
                  0
                )}
              </div>
            </div>
          </div>
        </div>
      );
    default: return (
      <div className="fixed w-[87.6vw] bottom-0 left-[12.2vw] flex h-[8vw] border-t-2 border-t-[#F1F1F1] bg-white items-center justify-between px-[1vw]">
        <div className="w-[15vw] h-[75%] border-[0.1vw] border-[#DBDBDB] bg-white rounded-lg ">
          <Link
            to="/gifticon"
            className="w-full h-full flex items-center justify-center gap-[1vw]"
          >
            <img
              src={gifts_image}
              alt={t("상품할인권")}
              className="w-[3vw] h-[3vw]"
            />
            <div className="text-[#b0b0b0] text-[2vw] leading-[2vw]">
              {t("상품할인권")}
            </div>
          </Link>
        </div>

        <div className="flex h-full items-center gap-2">
          <Link to="/log" className="h-full flex items-center">
            <div className="w-[15vw] h-[75%] border-[0.1vw] border-[#DBDBDB] bg-white rounded-lg flex items-center justify-center gap-[1vw] px-[1vw]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 34 34"
                fill="none"
                className="w-[2vw] h-[2vw]"
              >
                <rect y="6.10352e-05" width="34" height="6" fill="#D9D9D9" />
                <rect y="14.0001" width="34" height="6" fill="#D9D9D9" />
                <rect y="28.0001" width="34" height="6" fill="#D9D9D9" />
              </svg>
              <div className="max-h-full text-[#b0b0b0] text-[2.1vw] leading-[2vw]">
                {t("주문내역")}
              </div>
            </div>
          </Link>

          <div
            className="w-[15vw] h-[75%] bg-[#27CACA] rounded-lg flex items-center justify-center gap-[1vw] hover: cursor-pointer"
            onClick={openShoppingModal}
          >
            <div className="text-white text-[2.3vw] font-semibold">
              {t("주문하기")}
            </div>
            <div className="w-[2.5vw] h-[2.5vw] bg-white rounded-full flex items-center justify-center text-[#27CACA] text-[1.8vw]">
              {choiceMenus.reduce(
                (total, choiceMenu) => total + choiceMenu.amount,
                0
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
