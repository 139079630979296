import { t } from "i18next";
import FunElement from "./FunElement";
import ChatsIcon from "../../../assets/images/mint-messages-square.svg";
import ChatIcon from "../../../assets/images/mint-message-circle.svg";
import MusicIcon from '../../../assets/images/mint-MusicIcon.png'
import FunIcon from '../../../assets/images/mint-FunIcon.svg'

const FunList = ({ closeModal }) => {
  const FunElements = [
    { id: 1, title: t("1대1채팅"), uri: "/chatselection", icon: ChatsIcon },
    { id: 2, title: t("단체채팅"), uri: "/chatting", icon: ChatIcon },
    { id: 3, title: t("게임"), uri: "/game", icon: FunIcon },
    { id: 4, title: t("신청곡"), uri: "/song", icon: MusicIcon },
  ];
    
  return (
    <div className="w-[88.2vw] h-full fixed left-[12.2vw] z-10">
      <div className="w-[20vw] top-[22.4vw] left-0 absolute z-[2]">
        {FunElements.map((funElement) => (
          <FunElement
            key={funElement.id}
            title={funElement.title}
            uri={funElement.uri}
            icon={funElement.icon}
          />
        ))}
      </div>
      <div
        className="w-full h-full bg-black opacity-50 absolute top-0 left-0 z-[1]"
        onClick={closeModal}
      ></div>
    </div>
  )
};

export default FunList;
