import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const ChoiceSong = () => {
  const navigate = useNavigate();

  const moveBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-full bg-gr min-h-[100vh]">
      <div className="w-full h-[7vw] flex fill-white border-[#7B7B7B] border px-[1vw]">
        <div
          className="h-full flex text-2xl items-center justify-center gap-[0.5vw]"
          onClick={moveBack}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-[2vw] h-[2vw] fill-[#D9D9D9]"
            viewBox="0 0 24 24"
          >
            <path d="M16.041,24,6.534,14.48a3.507,3.507,0,0,1,0-4.948L16.052,0,18.17,2.121,8.652,11.652a.5.5,0,0,0,0,.707l9.506,9.52Z" />
          </svg>
          <div className="text-[1.8vw]">{t("이전")}</div>
        </div>
        <div className="flex-1 text-[1.8vw] font-bold flex items-center justify-center">
          {t("신청곡 목록")}
        </div>
      </div>
      <div className="w-full h-[90%] flex">
      <div className="h-full w-full">
          <div className="h-[87vh] w-full bg-[#EFF0F5] overflow-auto">
            <div className="p-1 flex justify-between">
              <div className="m-4">
                <div>모두와 같이 듣고 싶은 음악이 있나요?</div>
                <div>사진과 함께 원하는 노래를 신청해 보세요.</div>
              </div>
              <div className="my-auto bg-purple-500 p-2 m-4 rounded-md">신청곡 등록하기</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoiceSong;
