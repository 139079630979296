import { useContext, useEffect, useState } from "react";
import table from "../assets/images/table.png";
import stand from "../assets/images/Stand.png";
import wall from "../assets/images/wall.jpg";
import kt from "../assets/images/KT.png";

import v11 from '../assets/images/v11.png'
import v12 from '../assets/images/v12.png'
import v13 from '../assets/images/v13.png'
import v14 from '../assets/images/v14.png'
import v21 from '../assets/images/v21.png'

import porkbelly from "../assets/images/Porkbelly.png";
import chicken from '../assets/images/Chicken.png'
import italy from "../assets/images/Italy.png";
import china from "../assets/images/China.png";

const Display = () => {
  const [nowType, setNowType] = useState(1);
  const [nowSkin, setNowSkin] = useState("v21");
  
  
  return (
    <div className="w-screen h-screen bg-[#eff0f5] overflow-hidden">
      <div className="w-full h-[5vw] bg-[#ffffff] flex items-center pl-[1vw] gap-3">
        <img src={kt} className="h-[50%]" />
        <div className="flex items-center text-[2vw] font-bold  justify-center">
          하이오더
        </div>
        <div className="bg-gradient-to-r from-[#ed6100] via-[#cd8ab8] to-[#398ecf] h-[60%] border rounded-full flex items-center justify-center">
          <div className="h-[90%] w-[95%] bg-[#ffffff] rounded-full flex items-center justify-center px-[1vw] text-[1.5vw] font-medium whitespace-nowrap">
            매출 UP
          </div>
        </div>
        <div className="bg-gradient-to-r from-[#ed6100] via-[#cd8ab8] to-[#398ecf] h-[60%] border rounded-full flex items-center justify-center">
          <div className="h-[90%] w-[97%] bg-[#ffffff] rounded-full flex items-center justify-center px-[1vw] text-[1.5vw] font-medium whitespace-nowrap">
            인건비 DOWN
          </div>
        </div>
      </div>
      <div className="w-full flex py-[1vw]">
        <div className="w-[75vw] h-[40vw] flex justify-center">
          <div className="w-[90%] bg-[#ffffff] rounded-2xl flex justify-center items-center shadow-xl">
            <div className="w-[95%] h-[95%] bg-[#000000] rounded-2xl flex justify-center items-center p-[2vw]">
              <iframe
                src={`/display?type=${nowType}&skin=${nowSkin}`}
                frameborder="0"
                className="w-full h-full bg-white"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="w-[21vw] h-[40vw] overflow-auto flex justify-center fixed top-[6vw] right-[2vw] bg-white rounded-2xl shadow-2xl border-[#f4f4f4] border-[0.1vw]">
          <div className="h-screen">
            <div className="w-full">
              <div className="w-full my-[1vw] pl-[1vw] text-[1vw] font-bold">
                스킨 변경하기
              </div>
              <div className="border-[#27CACA] border-[0.15vw]"></div>
              <div className="p-[1vw] grid grid-cols-2 gap-[0.5vw]">
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowSkin("v11")}>
                  <img
                    src={v11}
                    alt="버전11"
                    className="w-full h-[4vw] filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    리스트형 스킨
                  </div>
                </div>
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowSkin("v12")}>
                  <img
                    src={v12}
                    alt="버전12"
                    className="w-full h-[4vw] filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    와이드형 스킨
                  </div>
                </div>
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowSkin("v13")}>
                  <img
                    src={v13}
                    alt="버전13"
                    className="w-full h-[4vw] filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    심플형 스킨(v13)
                  </div>
                </div>
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowSkin("v14")}>
                  <img
                    src={v14}
                    alt="버전14"
                    className="w-full h-[4vw] filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    심플형 스킨2(v14)
                  </div>
                </div>
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowSkin("v21")}>
                  <img
                    src={v21}
                    alt="버전21"
                    className="w-full h-[4vw] filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    심플형 스킨3(v21)
                  </div>
                </div>
                {/* <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowSkin("vBeta")}>
                  <img
                    src={vBeta}
                    alt="버전Beta"
                    className="w-full h-[4vw] filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    Beta
                  </div>
                </div> */}
              </div>
            </div>

            <div className="w-full">
              <div className="w-full my-[1vw] pl-[1vw] text-[1vw] font-bold">
                업종 변경하기
              </div>
              <div className="border-[#27CACA] border-[0.15vw]"></div>
              <div className="p-[1vw] grid grid-cols-2 gap-[0.5vw]">
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowType(1)}>
                  <img
                    src={china}
                    alt="china"
                    className="h-full filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    중식당
                  </div>
                </div>
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowType(2)}>
                  <img
                    src={chicken}
                    alt="china"
                    className="h-full filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    치킨집
                  </div>
                </div>
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowType(3)}>
                  <img
                    src={italy}
                    alt="italy"
                    className="h-full filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    이탈리아 레스토랑
                  </div>
                </div>
                {/* <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowType(4)}>
                  <img
                    src={china}
                    alt="china"
                    className="h-full filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    야끼토리집
                  </div>
                </div> */}
                <div className="relative w-[9.2vw] h-[4vw]" onClick={() => setNowType(5)}>
                  <img
                    src={porkbelly}
                    alt="porkbelly"
                    className="h-full filter brightness-50 rounded-md"
                  />
                  <div className="absolute inset-0 text-[#ffffff] font-bold text-[1vw] m-[0.5vw]">
                    삼겹살집
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="w-full my-[1.5vw] pl-[1vw] text-[1vw] font-bold">
                거치대 구경하기
              </div>
              <div className="border-[#27CACA] border-[0.15vw]"></div>
              <div className="w-full grid grid-cols-2 gap-[1.5vw] p-[1vw]">
                <div>
                  <img
                    src={stand}
                    className="object-cover w-full aspect-square"
                  />
                  <div className="w-full flex justify-center mt-[1vw] text-[1vw]">
                    스탠드형
                  </div>
                </div>
                <div>
                  <img
                    src={table}
                    className="object-cover w-full aspect-square"
                  />
                  <div className="w-full flex justify-center mt-[1vw] text-[1vw]">
                    탁상형
                  </div>
                </div>
                <div>
                  <img
                    src={wall}
                    className="object-cover w-full aspect-square"
                  />
                  <div className="w-full flex justify-center mt-[1vw] text-[1vw]">
                    벽결이형
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* <div className="w-full h-[3.5vw] flex px-[2vw] items-center text-[0.8vw] text-[#888] fixed bottom-0 left-0">
        Digico KT ADDRESS : 경기도 성남시 분당구 불정로 90(정자동) TEL : 100
        Copyright ⓒ Digico KT. All right reserved.
      </div> */}
    </div>
  );
};

export default Display;