import { t } from "i18next";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { SkinContext } from "../../../pages/OrderBoard";

const Menu = ({ id, title, icon, uri, selected, onClick, css }) => {
  const nowSkin = useContext(SkinContext)
  switch (nowSkin) {
    case "v11": case "v12": return (
      <Link to={uri}
      className={`flex h-full items-center cursor-pointer p-[0.8vw] rounded-md ${css}`}
      onClick={onClick}
      >
        <div className="flex gap-[0.5vw]">
          <img
            className="m-auto w-[2vw] h-[2vw] invert-[100%] sepia-[0%] saturate-[2085%] hue-rotate-[274deg] brightness-0 contrast-[101%]"
            src={icon}
            alt={title}
            />
          <div
            className="text-[1.4vw] font-medium break-words text-white"
            >
            {title}
          </div>
        </div>
      </Link>
    );
    case "v13" : case "v14": {
      const isV13 = nowSkin == 'v13'
      return (
          <Link to={uri}
          className={`flex items-center h-[7vw] w-[100%] border-b-[0.1vw] text-center border-b-[#343434] cursor-pointer ${
            selected
            ? "bg-gradient-to-br from-[#834CFF] to-[#05D790]"
            : ""
          }`}
          onClick={onClick}
          >
            <div className="inline-block leading-8 m-auto py-[1vw]">
              <img
                className={`m-auto w-[2vw] h-[2vw] ${id < 2 || id > 4 ? " invert-[100%] sepia-[0%] saturate-[2085%] hue-rotate-[274deg] brightness-[109%] contrast-[101%]"
                  : id == 2 || id == 3? "invert-[100%] sepia-[0%] saturate-[2085%] hue-rotate-[274deg] brightness-0 contrast-[101%]" : ""}`}
                src={icon}
                alt={title}
                />
              <div
                className="text-[1.4vw] font-medium break-words text-white"
                >
                {id == 1
                  ? t(title)
                  .split("\n")
                  .map((line, i) => <div key={i}>{line}</div>)
                  : title}
              </div>
            </div>
          </Link>
      );
    }
    default: if (id > 3) {
      return (
        <Link
        to={`/banner${icon}`}
          className="flex min-h-[5.9vw] max-h-[6.1vw] w-[100%] items-center text-center pl-[1.1vw] border-b-[0.1vw] border-b-[#343434] cursor-pointer gap-[1vw]"
        >
          <div
            className="m-auto text-[1.8vw] font-medium break-words pr-[1vw] : text-gray-400"
          >
            {id == 1
              ? t(title)
                  .split("\n")
                  .map((line, i) => <div key={i}>{line}</div>)
              : title}
          </div>
        </Link>
      );    
    }
    else {
      return (
        <div
        className={`flex min-h-[5.9vw] w-[100%] items-center pl-[1.1vw] border-b-[0.1vw] border-b-[#343434] cursor-pointer ${
          selected
          ? "gap-[1.2vw] border-l-[0.5vw] border-l-[#46B0AE] bg-[#2e2e2e]"
          : "gap-[1vw]"
          }`}
          onClick={onClick}
          >
          <img
            className={`${id === 1 ? "w-[1.8vw] h-[1.8vw]" : "w-[3vw] h-[3vw]"} ${
              selected || id === 3
              ? ""
              : "invert-[78%] sepia-[10%] saturate-[324%] hue-rotate-[179deg] brightness-[83%] contrast-[87%]"
              } ${id === 3 && "rounded-full object-cover"}`}
              src={icon}
              alt={title}
              />
          <div
            className={`text-[1.7vw] font-medium break-words ${
              selected ? "text-white" : "text-gray-400"
              }`}
              >
            {id == 1
              ? t(title)
              .split("\n")
              .map((line, i) => <div key={i}>{line}</div>)
              : title}
          </div>
        </div>
      );    
    }
  }
};
  
export default Menu;
