const LangElement = ({ name, icon, isSelected, onClick }) => {
  return (
    <div
      className={`w-[11.4vw] h-[calc(6.5vw*(75vw/100vw))] px-[1vw] pl-[0.3vw] my-[0.2vw] mx-[0.2vw] flex justify-center items-center gap-[0.3vw] rounded-full ${
        isSelected ? "bg-[#751fff]" : "bg-black"
      }`}
      onClick={onClick}
    >
      <div className="w-[2.8vw] h-[2.8vw] rounded-full">
        <img
          src={icon}
          alt={name}
          className="w-full h-full rounded-full object-cover"
        />
      </div>
      <div className="text-white text-[2.3vw]">{name}</div>
    </div>
  );
};

export default LangElement;
